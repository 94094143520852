import { useEffect, useState } from 'react';
import './ArticleList.scss';
import axios from 'axios';
import { BASE_URL } from '../../config';
import ListLoader from '../../components/Loader/ListLoader';
import coverImg from '../../assets/images/article.png';
import { Link, useParams } from 'react-router-dom';

function ArticleItem({ article }) {
    return <div className="article_item">
        <img className="article_item-cover_image" src={coverImg} alt="Cover" />
        <div className="article_item-context">
            <Link to={`/article/view/${article._id}`} className="article_item-title">{article.title}</Link>
            <div>{new Date(article.publicationDate).toLocaleDateString('en', { month: 'long', year: 'numeric' })}</div>
        </div>
    </div>
}

export default function ArticleList() {
    const { volumeId, issueId } = useParams();
    const [articles, setArticles] = useState(null);

    useEffect(() => {
        // Create query parameters based on volumeId and issueId
        const params = {};
        if (volumeId) params.volume = volumeId;
        if (issueId) params.issue = issueId;

        axios.get(`${BASE_URL}/articles`, { params })
            .then(res => {
                setArticles(res.data);
            })
            .catch(console.error);
    }, [volumeId, issueId]); // Add dependencies to re-fetch on param change

    if (articles === null) return <ListLoader />;

    return (
        <div className="page_body">
            <div className="article_list">
                <div className="article_list--header">
                    <h1 className='article_list--title'>BAUK Articles</h1>
                    <div className='article_list--sub_title'>Volume {volumeId}, Issue {issueId}</div>
                </div>
                {articles.length === 0
                    ? <div className='article_list--empty'>No item found</div>
                    : articles.map(article => <ArticleItem article={article} key={article._id} />)
                }

            </div>
        </div>
    );
}